import React from "react";
import axios from "axios";
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Collapse from "@material-ui/core/Collapse";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { store } from "react-notifications-component";

import BgParticles from "./components/BgParticles";

//ASSETS
import logoVertical from "../../commons/assets/img/logo-vertical.png";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      collapsed: false,
      userEmail: "",
      userEmailE: false,
      userPassword: "",
      userPasswordE: false,
      loading: false,
    };
  }

  componentDidMount() {
    const that = this;
    setTimeout(function () {
      that.setState({ collapsed: true });
    }, 1500);
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value, [name + "E"]: false });
  };

  fnLogin = () => {
    const that = this;
    const { dev, backEnd, handleLogin } = this.props;
    const { userEmail, userPassword } = this.state;

    if (userEmail === "") {
      this.setState({ userEmailE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write your user email.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (userPassword === "") {
      this.setState({ userPasswordE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write your user password.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else {
      this.setState({ loading: true });

      axios.post(backEnd + "api/login/login.php",{
        dev:dev,
        userEmail: userEmail,
        userPassword: userPassword,
        },{
        headers: { "Content-Type": "application/json" },
        }
      ).then(function (response) {
        var data = response.data;
        that.setState({ loading: false });

        if (data[0].error === 1) {
          handleLogin(data[0]);
        } else if (data[0].error === 2) {
          store.addNotification({
            title: "Error!",
            message: "You don't have access permision.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else if (data[0].error === 3) {
          that.setState({ userPasswordE: true }, () => {
            store.addNotification({
              title: "Error!",
              message: "Wrong password.",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          });
        } else if (data[0].error === 4) {
          that.setState({ userEmailE: true }, () => {
            store.addNotification({
              title: "Error!",
              message: "Email doesn't exist.",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          });
        }
      }).catch(function (error)
      {
        that.setState({ loadingLogin: false });
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    }
  };

  render() {
    const { build, version, handleOpenDialogNewVersion } = this.props;
    const { loading } = this.state;
    const {
      collapsed,
      showPassword,
      userEmail,
      userEmailE,
      userPassword,
      userPasswordE,
    } = this.state;

    return (
      <div className="container">
        <BgParticles />
        <div className="containerLogin">
          <Slide direction="down" in={collapsed} mountOnEnter unmountOnExit>
            <Paper
              elevation={3}
              style={{
                backgroundColor: "rgba(36, 36, 40, .9)",
                borderBottomLeftRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              <Grid
                container
                style={{
                  padding: "20px",
                }}
              >
                <Grid item xs={12}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <img
                      src={logoVertical}
                      style={{
                        width: "190px",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={userEmailE}
                    id="userEmail"
                    label="User Email"
                    variant="outlined"
                    onChange={this.handleChange("userEmail")}
                    value={userEmail}
                    style={{
                      width: "100%",
                      marginTop: "30px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccountCircle
                            style={{
                              color: "#FFF",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={userPasswordE}
                    id="userPassword"
                    label="Password"
                    variant="outlined"
                    type={!showPassword ? "password" : "text"}
                    onChange={this.handleChange("userPassword")}
                    value={userPassword}
                    style={{
                      width: "100%",
                      marginTop: "30px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState({ showPassword: !showPassword })
                            }
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility style={{ color: "#FFF" }} />
                            ) : (
                              <VisibilityOff style={{ color: "#FFF" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => this.fnLogin()}
                      style={{
                        borderRadius: "20px",
                      }}
                    >
                      <font
                        style={{
                          textTransform: "none",
                        }}
                      >
                        Login
                      </font>
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "30px",
                    }}
                  >
                    <a style={{
                      cursor: "pointer"
                    }}
                       onClick={()=>handleOpenDialogNewVersion(1)}
                    >
                    <font
                      style={{
                        fontFamily: "Poppins SemiBold",
                        color: "#FFF",
                        fontSize: "13px",
                      }}
                    >
                      v {version+"."+build}
                    </font>
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Slide>
        </div>
        <Backdrop open={!collapsed || loading}>
          <CircularProgress style={{ color: "#FFF" }} />
        </Backdrop>
      </div>
    );
  }
}

export default LoginPage;
