import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { store } from "react-notifications-component";
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

//ICONS
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

//COMPONENTES
import axios from "axios";
import InputAdornment from "@material-ui/core/InputAdornment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogLoans extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      open:false,
      loanData:[],
      traces: [],
      loanIdSelected: ""
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const that = this;
    const { loansData } = this.props;
    const { open } = this.state;
    if(nextProps.open && open === false) {
      this.setState({ open:true}, () => {
        var loanId = loansData[0].loanId;
        this.setState({ loanIdSelected:loanId }, () => {
          this.loadLoanInfo(loanId);
        })
      });
    }
  }

  loadLoanInfo = (loanId) => {
    const that = this;
    const { dev, backEnd, handleLoading } = this.props;
    const dataUser = JSON.parse(localStorage.getItem("_agogosDataUser"));
    var userId = dataUser.userId;

    setTimeout(function () {
      handleLoading(true);
      axios
        .post(
          backEnd + "api/home/loans/loansInfo.php",
          {
            dev:dev,
            userId: userId,
            loanId:loanId
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(function (response) {
          var data = response.data;
          var loanData = data[5];
          that.setState({ loanData: loanData}, () => {
            that.loadLoanTraces(loanId);
          });
        })
        .catch(function (error) {
          handleLoading(false);
          var e = JSON.parse(JSON.stringify(error));

          var eText = e.message;
          if (eText === "Network Error") {
            eText = "Error de conexión.";
          }
          if (eText === undefined) {
            eText = "Error unknown";
          }
          store.addNotification({
            title: "Error!",
            message: eText,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        });
    },700);
  }

  loadLoanTraces = (loanId) => {
    const that = this;
    const { dev, backEnd, handleLoading } = this.props;
    const dataUser = JSON.parse(localStorage.getItem("_agogosDataUser"));
    var userId = dataUser.userId;

    handleLoading(true);
    axios.post(backEnd + "api/home/loans/tracesInfo.php",
      {
        dev:dev,
        userId: userId,
        loanId:loanId
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then(function (response) {
        handleLoading(false);
        var data = response.data;
        var traces = data[0];
        that.setState({ traces: traces});
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  handleClose = () => {
    const { handleClose } = this.props;
    this.setState(this.initialState, () => {
      handleClose();
    });
  };

  handleChangeDate = (date) => {
    this.setState({ startDate: date });
  };

  render() {
    const that = this;
    const { classes, open, loansData } = this.props;
    const { loanData, loanIdSelected, traces } = this.state

    var dateS = loanData.fechaInicioLoan;
    var dateC = loanData.closingDate;

    if(Object.keys(loanData).length > 0) {
      if (dateS !== null && dateS !== "") {
        var dateDataS = dateS.split("-");
        var y = dateDataS[0];
        var m = dateDataS[1];
        var d = dateDataS[2];
        dateS = m + "/" + d + "/" + y;
      }
      if (dateC !== null && dateC !== "") {
        var dateDataC = dateC.split("-");
        var y = dateDataC[0];
        var m = dateDataC[1];
        var d = dateDataC[2];
        dateC = m + "/" + d + "/" + y;
      }
    }

    return (
      <>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          style={{ paddingTop: "30px" }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Loan Units
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container style={{ paddingTop: "0px" }}>
              <Grid item xs={3} className={classes.gridPading}>
                <Typography variant="h6" gutterBottom>
                  Loan Number
                </Typography>
                <Paper style={{
                  backgroundColor:'#323232'
                }}>
                  <MenuList>
                    {loansData.map(function (arr, index) {
                      var color = "inherit";
                      if(loanIdSelected === arr.loanId) {
                        color = "#212121";
                      }
                      return(
                        <MenuItem
                          style={{backgroundColor:color}}
                          onClick={()=>that.setState({loanIdSelected:arr.loanId}, () => {
                            that.loadLoanInfo(arr.loanId);
                          })}
                        >
                          {arr.loanNumber}
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </Paper>
              </Grid>
              <Grid item xs={9} className={classes.gridPading}>
                {Object.keys(loanData).length > 0 &&
                <>
                <Grid container style={{
                  borderBottom:'1px dashed #ccc',
                  paddingBottom:'30px'
                }}>
                  <Grid item xs={6} lg={4} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="loanProgramId"
                      label="Loan Program"
                      variant="outlined"
                      value={loanData.loanProgram}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={4} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="loanTypeId"
                      label="Loan Type"
                      variant="outlined"
                      value={loanData.loanType}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={4} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="closingDate"
                      label="Closing Date"
                      variant="outlined"
                      value={dateC}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="borrowerLastName"
                      label="Borrower Last Name"
                      variant="outlined"
                      value={loanData.borrowerLastName}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="borrowerFirstName"
                      label="Borrower First Name"
                      variant="outlined"
                      value={loanData.borrowerFirstName}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="startDate"
                      label="Start Date"
                      variant="outlined"
                      value={dateS}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="amount"
                      label="Loan Amount"
                      variant="outlined"
                      value={loanData.amount}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="investor"
                      label="Lender"
                      variant="outlined"
                      value={loanData.investor}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="lo"
                      label="Loan Officer"
                      variant="outlined"
                      value={loanData.lo}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="realtor"
                      label="Realtor Partner"
                      variant="outlined"
                      value={loanData.realtor !== null ? loanData.realtor : ""}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="processor"
                      label="Assigned Processor"
                      variant="outlined"
                      value={loanData.processor !== null ? loanData.processor : ""}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} className={classes.gridPading}>
                    <TextField
                      disabled={true}
                      id="assignedLOA"
                      label="Assigned LOA"
                      variant="outlined"
                      value={loanData.assignedLOA !== null ? loanData.assignedLOA : ""}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{
                  marginTop:'10px'
                }}>
                  <Grid item xs={12}>
                    <List className={classes.root}>
                      {Object.keys(traces).length > 0 &&
                      traces.map(function (arr, index) {

                        var dO = arr.dateSeguimiento.split(" ");
                        var dd = dO[0].split("-");

                        var y = dd[0];
                        var m = dd[1];
                        var d = dd[2];
                        var dateFixed = m+"/"+d+"/"+y+" "+dO[1];

                        return (
                          <>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar>
                                  {arr.userName.charAt(0)}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={dateFixed}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      className={classes.inline}
                                      color="textPrimary"
                                    >
                                      {arr.userName}
                                    </Typography>
                                    {" — "+arr.seguimiento}
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </>
                        )})
                      }
                    </List>
                  </Grid>
                </Grid>
                </>
                }
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  gridPading: {
    padding:'15px 10px'
  },
  inline: {
    display: 'inline',
  },
});

DialogLoans.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogLoans);
