import React from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import { store } from "react-notifications-component";
import AccountCircle from "@material-ui/icons/AccountCircle";

class DialogAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      investor:"",
      investorE:false,
      lo:"",
      loE:false,
      loEmail:"",
      loEmailE:false,
      loCellNumber:"",
      loCellNumberE:false,
      realtor:"",
      realtorE:false,
      realtorEmail:"",
      realtorEmailE:false,
      realtorCellNumber:"",
      realtorCellNumberE:false,
      trace:"",
      traceE:false
    };
  }

  fnSaveNewOptions = () => {
    const that = this;
    const { dev, backEnd, option, loadLoans, handleClose, handleLoading } = this.props;
    const { investor, lo, loEmail, realtor, realtorEmail, loCellNumber, realtorCellNumber } = this.state;
    const dataUser = JSON.parse(localStorage.getItem("_agogosDataUser"));
    var userId = dataUser.userId;

    if(option === "addInvestor" && investor === "") {
      this.setState({ investorE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write the new investor.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if(option === "addLo" && lo === "") {
      this.setState({ loE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write the new loan officer.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if(option === "addLo" && (loEmail === "" || !this.handleValidateEmail(loEmail))) {
      this.setState({ loEmailE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write a valid email.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if(option === "addLo" && (loCellNumber === "" || loCellNumber.length < 10 || !this.handleValidateOnlyNumbers(loCellNumber))) {
      this.setState({ loCellNumberE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write a valid mobile number.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if(option === "addRealtor" && realtor === "") {
      this.setState({ realtorE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write the new Real state agent.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if(option === "addRealtor" && (realtorEmail === "" || !this.handleValidateEmail(realtorEmail))) {
      this.setState({ realtorEmailE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write a valid email.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if(option === "addRealtor" && (realtorCellNumber === "" || realtorCellNumber.length < 10 || !this.handleValidateOnlyNumbers(realtorCellNumber))) {
      this.setState({ realtorCellNumberE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write a valid mobile number.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else {
      handleLoading(true);

      axios.post(backEnd + "api/home/loans/addNewOptions.php",{
        dev:dev,
        userId: userId,
        option:option,
        investor:investor,
        lo:lo,
        loEmail:loEmail,
        realtor:realtor,
        realtorEmail:realtorEmail,
        loCellNumber:loCellNumber,
        realtorCellNumber:realtorCellNumber
        },{
          headers: { "Content-Type": "application/json" },
        }
      ).then(function (response) {
        handleLoading(false);
        var data = response.data;

        if(data[0].error === 1) {
          setTimeout(function () {
            store.addNotification({
              title: "Success!",
              message: "New option added succesfully.",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
            that.setState({investor:""});
            loadLoans();
            handleClose();
          },500);
        } else {
          store.addNotification({
            title: "Error!",
            message: "An error ocurred when traying to add a new option. "+data[0].error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      }).catch(function (error)
      {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    }
  }

  fnSaveTrace = () => {
    const that = this;
    const { dev, backEnd, option, handleClose, handleLoading, loanId, loadLoanTraces } = this.props;
    const { trace } = this.state;
    const dataUser = JSON.parse(localStorage.getItem("_agogosDataUser"));
    var userId = dataUser.userId;

    if(trace === "") {
      this.setState({ traceE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write your loan update.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else {
      handleLoading(true);
      axios.post(backEnd + "api/home/loans/addLoanTrace.php",{
        dev:dev,
        userId: userId,
        trace:trace,
        loanId:loanId
        },{
          headers: { "Content-Type": "application/json" },
        }
      ).then(function (response) {
        handleLoading(false);
        var data = response.data;

        if(data[0].error === 1) {
          setTimeout(function () {
            store.addNotification({
              title: "Success!",
              message: "Update saved succesfully.",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
            loadLoanTraces();
            handleClose();
          },500);
        } else {
          store.addNotification({
            title: "Error!",
            message: "An error ocurred when traying to save your update. "+data[0].error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      }).catch(function (error)
      {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({[name]: event.target.value, [name + 'E']: false});
  };

  handleValidateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  handleValidateOnlyNumbers = (cellNumber) => {
    return /^[0-9]+$/.test(cellNumber);
  }

  handleClose = () => {
    const {handleClose} = this.props;
    this.setState(this.initialState, () => {
      handleClose();
    });
  }

  render() {
    const { classes, option, open } = this.props;
    const { investor, investorE, lo, loE, loEmail, loEmailE, realtor, realtorE, realtorEmail, realtorEmailE, loCellNumber, loCellNumberE, realtorCellNumber, realtorCellNumberE, trace, traceE } = this.state;
    var title = "";
    if(option === "addInvestor") {title = "Add Investor";}
    if(option === "addLo") {title = "Add Loan Officer";}
    if(option === "addRealtor") {title = "Add New Agent";}
    if(option === "addTrace") {title = "Add Update";}
    return (
      <Dialog maxWidth="md" open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {option === "addInvestor" &&
          <Grid
            container
            style={{width:'500px'}}
          >
            <Grid item xs={12} className={classes.gridPading}>
              <TextField
                error={investorE}
                id="investor"
                label="Investor"
                variant="outlined"
                onChange={this.handleChange("investor")}
                value={investor}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
          }
          {option === "addLo" &&
          <Grid
            container
            style={{width:'500px'}}
          >
            <Grid item xs={12} className={classes.gridPading}>
              <TextField
                error={loE}
                id="lo"
                label="Loan Officer"
                variant="outlined"
                onChange={this.handleChange("lo")}
                value={lo}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridPading}>
              <TextField
                error={loEmailE}
                id="loEmail"
                label="Email"
                variant="outlined"
                onChange={this.handleChange("loEmail")}
                value={loEmail}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridPading}>
              <TextField
                error={loCellNumberE}
                id="loCellNumber"
                label="Mobile Number"
                variant="outlined"
                onChange={this.handleChange("loCellNumber")}
                value={loCellNumber}
                style={{
                  width: "100%",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      +1
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          }
          {option === "addRealtor" &&
          <Grid
            container
            style={{width:'500px'}}
          >
            <Grid item xs={12} className={classes.gridPading}>
              <TextField
                error={realtorE}
                id="relator"
                label="Agent"
                variant="outlined"
                onChange={this.handleChange("realtor")}
                value={realtor}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridPading}>
              <TextField
                error={realtorEmailE}
                id="realtorEmail"
                label="Email"
                variant="outlined"
                onChange={this.handleChange("realtorEmail")}
                value={realtorEmail}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridPading}>
              <TextField
                error={realtorCellNumberE}
                id="realtorCellNumber"
                label="Mobile Number"
                variant="outlined"
                onChange={this.handleChange("realtorCellNumber")}
                value={realtorCellNumber}
                style={{
                  width: "100%",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      +1
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          }
          {option === "addTrace" &&
          <Grid
            container
            style={{width: '500px'}}
          >
            <Grid item xs={12} className={classes.gridPading}>
              <TextField
                multiline={true}
                rows={5}
                rowsMax={5}
                error={traceE}
                id="trace"
                label="Update"
                variant="outlined"
                onChange={this.handleChange("trace")}
                value={trace}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.handleClose()} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={option !== "addTrace" ? () => this.fnSaveNewOptions() : () => this.fnSaveTrace()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  gridPading: {
    padding:'10px'
  }
});

DialogAdd.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogAdd);
