import React from "react";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { store } from "react-notifications-component";
import Paper from "@material-ui/core/Paper";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Button from '@material-ui/core/Button';
import CountUp from 'react-countup';
import '../../../commons/assets/css/tableMilestones.css';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  LineChart,
} from 'recharts';

//COMPONENTES
import DialogLoans from "../components/DialogLoans";
import Typography from "@material-ui/core/Typography";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class TabMilestones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataUser:[],
      milestonesData:[],
      loansData:[],
      foundedChartData:[],
      monthlyMilestonesChartData:[],
      milestonesAmountChartData:[],
      openDialogLoans:false,
      totalJanuaryToDateFounded:0,
      totalAmountActive:0,
      totalUnitsActive:0,
      totalUnitsFunded:0,
      milestoneIdSelected:"1",
      milestoneSelected:"Application Created/Imported",
      keyReloadChart:0,
      totalAmountLeadActive:0,
      totalUnitsLeadActive:0,
    };
  }

  componentDidMount() {
    const dataUser = JSON.parse(localStorage.getItem("_agogosDataUser"));
    this.setState({ dataUser: dataUser }, () => {
      this.loadMilestons();
    });
  }

  loadMilestons = (reloadChart) => {
    const that = this;
    const { dev, backEnd, handleLoading } = this.props;
    const { dataUser, milestoneIdSelected, keyReloadChart } = this.state;
    var userId = dataUser.userId;
    handleLoading(true);

    axios
      .post(
        backEnd + "api/home/milestones/milestonesInfo.php",
        {
          dev:dev,
          userId: userId,
          milestonIdChart:milestoneIdSelected
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        handleLoading(false);
        var data = response.data;
        var milestonesData = data[0];
        var totalJanuaryToDateFounded = data[1];
        var foundedChartData = data[2];
        var totalAmountActive = data[3];
        var totalUnitsActive = data[4];
        var totalUnitsFunded = data[5];
        var monthlyMilestonesChartData = data[6];
        var milestonesAmountChartData = data[7];
        var totalAmountLeadActive = data[8];
        var totalUnitsLeadActive = data[9];

        that.setState({ milestonesData: milestonesData, totalJanuaryToDateFounded:totalJanuaryToDateFounded, foundedChartData:foundedChartData, totalAmountActive:totalAmountActive, totalUnitsActive:totalUnitsActive, totalUnitsFunded:totalUnitsFunded, monthlyMilestonesChartData:monthlyMilestonesChartData, milestonesAmountChartData:milestonesAmountChartData, totalAmountLeadActive:totalAmountLeadActive, totalUnitsLeadActive:totalUnitsLeadActive }, () => {
          if(reloadChart !== undefined) {
            that.setState({ keyReloadChart:keyReloadChart+1 });
          }
        });
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  handleOpenDialogLoans = (data) => {
    this.setState({ loansData: data }, () => {
      this.setState({ openDialogLoans: true });
    });
  };

  handleCloseDialogLoans = () => {
    this.setState({ loansData: [], openDialogLoans: false });
  };

  handleChangeMileston = (value) => {
    const { milestonesData } = this.state;
    //this.setState({ [name]: event.target.value, [name + "E"]: false });
    var indexMilestones = milestonesData.findIndex((x) => x.milestoneId === value);
    var milestone = milestonesData[indexMilestones].milestoneName;
    this.setState({ milestoneIdSelected: value, milestoneSelected: milestone }, () => {
      this.loadMilestons(true);
    });
  };

  renderCell = (d, index) => {
    const { classes } = this.props;
    if(index === undefined) {
      return (
        <Grid container>
          <Grid item xs={12} className={classes.tContent} style={{textAlign:'left'}}>
            {d}
          </Grid>
        </Grid>
      );
    } else if(index === "totalsToday" || index === "totalsYesterday" || index === "totalsMonthToDate" || index === "totalsThreeMonthToDate" || index === "totalsJanuaryToDate") {
      var data = "";
      if( index === "totalsToday" ) { data = d.totalsToday; }
      if( index === "totalsYesterday" ) { data = d.totalsYesterday; }
      if( index === "totalsMonthToDate" ) { data = d.totalsMonthToDate; }
      if( index === "totalsThreeMonthToDate" ) { data = d.totalsThreeMonthToDate; }
      if( index === "totalsJanuaryToDate" ) { data = d.totalsJanuaryToDate; }

      var amount = data[0];
      var unit = data[1];
      var color = "#FFF";
      if(amount === "$0.00"){ color = "transparent";}

      return (
        <Grid container>
          <Grid
            item
            xs={8}
            className={classes.tContent}
            style={{ borderRight: "1px solid #ccc", color: color }}
          >
            <Button disabled={true} style={{ padding: "0px", color: color }}>
              {amount}
            </Button>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.tContent}
            style={{ color: color }}
          >
            {unit > 0 ? (
              <Button
                style={{ padding: "0px" }}
                onClick={() => this.handleOpenDialogLoans(data[2])}
              >
                {unit}
              </Button>
            ) : (
              <Button
                disabled={true}
                style={{ padding: "0px", color: "transparent" }}
              >
                {unit}
              </Button>
            )}
          </Grid>
        </Grid>
      );
    }
  }

  renderColorfulLegendText = (value, entry) => {
    const { color } = entry;
    return <span style={{ color, fontFamily:'Poppins Regular' }}>{value}</span>;
  }

  renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if(value > 0) {
      var v = formatter.format(value);
      return (
        v
      );
    } else {
      return (
        ""
      );
    }
  };

  renderCustomizedLineLabel = (props) => {
    const { x, y, width, height, value, stroke } = props;
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if(value > 0) {
      var v = formatter.format(value);
      return <text x={x} y={y} dy={-4} fill="#FFF" fontSize={11} textAnchor="middle">{v}</text>
    } else {
      return (
        ""
      );
    }
  }

  renderDataFormater = (number) => {
    if(number > 1000000000){
      return (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return (number/1000).toString() + 'K';
    }else{
      return number.toString();
    }
  }

  render() {
    const that = this;
    const { classes, dev, backEnd, handleLoading } = this.props;
    const { milestonesData, loansData, openDialogLoans, totalJanuaryToDateFounded, foundedChartData, totalAmountActive, totalUnitsActive, totalUnitsFunded, monthlyMilestonesChartData, milestoneSelected, milestoneIdSelected, keyReloadChart, milestonesAmountChartData, totalAmountLeadActive, totalUnitsLeadActive } = this.state;
    return(
      <Grid container>
        <Grid item xs={12} lg={4}>
          <div style={{
            width:'100%',
            paddingTop:'0px',
            paddingBottom:'35px',
            textAlign:'center'
          }}>
            <Grid container>
              <Grid item xs={12} lg={9}>
                <div style={{width:'100%', textAlign:'left'}}>
                  <Typography variant="h7" className={classes.title} style={{color:'#FFF'}}>
                    Total Active Loans:
                  </Typography>
                </div>
                <div style={{width:'100%', textAlign:'left'}}>
                  <CountUp
                    className="counter"
                    start={0}
                    end={totalAmountActive}
                    duration={2.75}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="$ "
                  >
                    {({ countUpRef }) => (
                      <span style={{
                        color:'#c5e1a5',
                        fontFamily: 'Poppins Bold',
                        fontSize: '35px'
                      }} ref={countUpRef} />
                    )}
                  </CountUp>
                </div>
              </Grid>
              <Grid item xs={12} lg={3}>
                <div style={{width:'100%', textAlign:'left'}}>
                  <Typography variant="h7" className={classes.title} style={{color:'#FFF'}}>
                    Total Units:
                  </Typography>
                </div>
                <div style={{width:'100%', textAlign:'left'}}>
                  <CountUp
                    className="counter"
                    start={0}
                    end={totalUnitsActive}
                    duration={2.75}
                    decimals={0}
                  >
                    {({ countUpRef }) => (
                      <span style={{
                        color:'#c5e1a5',
                        fontFamily: 'Poppins Bold',
                        fontSize: '35px'
                      }} ref={countUpRef} />
                    )}
                  </CountUp>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={9}>
                <div style={{width:'100%', textAlign:'left', marginTop:'20px'}}>
                  <Typography variant="h7" className={classes.title} style={{color:'#FFF'}}>
                    Total Active Leads:
                  </Typography>
                </div>
                <div style={{width:'100%', textAlign:'left'}}>
                  <CountUp
                    className="counter"
                    start={0}
                    end={totalAmountLeadActive}
                    duration={2.75}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="$ "
                  >
                    {({ countUpRef }) => (
                      <span style={{
                        color:'#fff59d',
                        fontFamily: 'Poppins Bold',
                        fontSize: '35px'
                      }} ref={countUpRef} />
                    )}
                  </CountUp>
                </div>
              </Grid>
              <Grid item xs={12} lg={3}>
                <div style={{width:'100%', textAlign:'left', marginTop:'20px'}}>
                  <Typography variant="h7" className={classes.title} style={{color:'#FFF'}}>
                    Total Units:
                  </Typography>
                </div>
                <div style={{width:'100%', textAlign:'left'}}>
                  <CountUp
                    className="counter"
                    start={0}
                    end={totalUnitsLeadActive}
                    duration={2.75}
                    decimals={0}
                  >
                    {({ countUpRef }) => (
                      <span style={{
                        color:'#fff59d',
                        fontFamily: 'Poppins Bold',
                        fontSize: '35px'
                      }} ref={countUpRef} />
                    )}
                  </CountUp>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={9}>
                <div style={{width:'100%', textAlign:'left', marginTop:'20px'}}>
                  <Typography variant="h7" className={classes.title} style={{color:'#FFF'}}>
                    Current Fundings:
                  </Typography>
                </div>
                <div style={{width:'100%', textAlign:'left'}}>
                  <CountUp
                    className="counter"
                    start={0}
                    end={totalJanuaryToDateFounded}
                    duration={2.75}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="$ "
                  >
                    {({ countUpRef }) => (
                      <span style={{
                        color:'#4fc3f7',
                        fontFamily: 'Poppins Bold',
                        fontSize: '35px'
                      }} ref={countUpRef} />
                    )}
                  </CountUp>
                </div>
              </Grid>
              <Grid item xs={12} lg={3}>
                <div style={{width:'100%', textAlign:'left', marginTop:'20px'}}>
                  <Typography variant="h7" className={classes.title} style={{color:'#FFF'}}>
                    Total Units:
                  </Typography>
                </div>
                <div style={{width:'100%', textAlign:'left'}}>
                  <CountUp
                    className="counter"
                    start={0}
                    end={totalUnitsFunded}
                    duration={2.75}
                    decimals={0}
                  >
                    {({ countUpRef }) => (
                      <span style={{
                        color:'#4fc3f7',
                        fontFamily: 'Poppins Bold',
                        fontSize: '35px'
                      }} ref={countUpRef} />
                    )}
                  </CountUp>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} lg={8}>
          <div style={{
            width:'100%',
            height:250,
            paddingBottom:'30px'
          }}>
            <ResponsiveContainer>
              <ComposedChart
                layout="vertical"
                data={foundedChartData}
                margin={{
                  top: 0, right: 0, bottom: 0, left: 30,
                }}
              >
                <CartesianGrid
                  horizontal={false}
                  strokeDasharray="4 4"
                  stroke="#aab8c2"
                />
                <XAxis fontFamily={'Poppins Regular'} type="number" />
                <YAxis fontFamily={'Poppins Regular'} dataKey="name" type="category" />
                <Tooltip labelStyle={{color:'#FFF'}}/>
                <Legend formatter={this.renderColorfulLegendText}/>
                <Bar dataKey="This Month to Date" barSize={30} fill="#cddc39" radius={[0, 10, 10, 0]}>
                  <LabelList
                    position="inside"
                    content={this.renderCustomizedLabel}
                  />
                </Bar>
                <Bar dataKey="3 Months Ago to Date" barSize={30} fill="#7cb342" radius={[0, 10, 10, 0]}>
                  <LabelList
                    position="inside"
                    content={this.renderCustomizedLabel}
                  />
                </Bar>
                <Bar dataKey="Year to Date" barSize={30} fill="#558b2f" radius={[0, 10, 10, 0]}>
                  <LabelList
                    position="inside"
                    content={this.renderCustomizedLabel}
                  />
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        {Object.keys(milestonesData).length > 0 &&
        <>
          <Grid item xs={12}>
            <ReactTableFixedColumns
              showPagination={false}
              defaultPageSize={Object.keys(milestonesData).length}
              data={milestonesData}
              style={{
                height:'400px'
              }}
              columns={[{
                id: "milestone",
                Header: 'Milestone',
                fixed: 'left',
                sortable: false,
                accessor: d => that.renderCell(d.milestone)
              }, {
                id: "today",
                Header: (
                  <Grid container>
                    <Grid item xs={12} className={classes.tHeader}>
                      Today
                    </Grid>
                    <Grid item xs={8} className={classes.tHeaderSub} style={{borderRight: '1px solid #ccc'}}>
                      Loan Amount
                    </Grid>
                    <Grid item xs={4} className={classes.tHeaderSub}>
                      Unit
                    </Grid>
                  </Grid>
                ),
                sortable: false,
                accessor: d => that.renderCell(d, "totalsToday")
              }, {
                id: "yesterday",
                Header: (
                  <Grid container>
                    <Grid item xs={12} className={classes.tHeader}>
                      Yesterday
                    </Grid>
                    <Grid item xs={8} className={classes.tHeaderSub} style={{borderRight: '1px solid #ccc'}}>
                      Loan Amount
                    </Grid>
                    <Grid item xs={4} className={classes.tHeaderSub}>
                      Unit
                    </Grid>
                  </Grid>
                ),
                sortable: false,
                accessor: d => that.renderCell(d, "totalsYesterday")
              }, {
                id: "totalsMonthToDate",
                Header: (
                  <Grid container>
                    <Grid item xs={12} className={classes.tHeader}>
                      This Month to Date
                    </Grid>
                    <Grid item xs={8} className={classes.tHeaderSub} style={{borderRight: '1px solid #ccc'}}>
                      Loan Amount
                    </Grid>
                    <Grid item xs={4} className={classes.tHeaderSub}>
                      Unit
                    </Grid>
                  </Grid>
                ),
                sortable: false,
                accessor: d => that.renderCell(d, "totalsMonthToDate")
              }, {
                id: "totalsThreeMonthToDate",
                Header: (
                  <Grid container>
                    <Grid item xs={12} className={classes.tHeader}>
                      3 Months Ago to Date
                    </Grid>
                    <Grid item xs={8} className={classes.tHeaderSub} style={{borderRight: '1px solid #ccc'}}>
                      Loan Amount
                    </Grid>
                    <Grid item xs={4} className={classes.tHeaderSub}>
                      Unit
                    </Grid>
                  </Grid>
                ),
                sortable: false,
                accessor: d => that.renderCell(d, "totalsThreeMonthToDate")
              }, {
                id: "age",
                Header: (
                  <Grid container>
                    <Grid item xs={12} className={classes.tHeader}>
                      Year to Date
                    </Grid>
                    <Grid item xs={8} className={classes.tHeaderSub} style={{borderRight: '1px solid #ccc'}}>
                      Loan Amount
                    </Grid>
                    <Grid item xs={4} className={classes.tHeaderSub}>
                      Unit
                    </Grid>
                  </Grid>
                ),
                sortable: false,
                accessor: d => that.renderCell(d, "totalsJanuaryToDate")
              }]}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{
              marginTop: '30px',
              width: '100%',
              textAlign: 'center'
            }}>
              <Typography variant="h6" className={classes.title} style={{color: '#FFF'}}>Total Volume by Milestone &
                Time Period</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={3}>
                <TextField
                  select
                  id="mileston"
                  label="Milestone"
                  variant="outlined"
                  value={milestoneIdSelected}
                  onChange={(event) => this.handleChangeMileston(event.target.value)}
                  style={{
                    width: "100%",
                  }}
                >
                  {milestonesData.map(function (arr, index) {
                    return (
                      <MenuItem key={index} value={arr.milestoneId}>
                        {arr.milestoneName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <div style={{
                  marginTop: '30px',
                  width: '100%',
                  textAlign: 'center',
                  height: '350px'
                }}>
                  <ResponsiveContainer>
                    <ComposedChart
                      layout="vertical"
                      data={milestonesAmountChartData}
                      margin={{
                        top: 0, right: 0, bottom: 0, left: 30,
                      }}
                    >
                      <CartesianGrid
                        horizontal={false}
                        strokeDasharray="4 4"
                        stroke="#aab8c2"
                      />
                      <XAxis fontFamily={'Poppins Regular'} type="number" />
                      <YAxis fontFamily={'Poppins Regular'} dataKey="name" type="category" />
                      <Tooltip labelStyle={{color:'#FFF'}}/>
                      <Legend formatter={this.renderColorfulLegendText}/>
                      <Bar dataKey="This Month to Date" barSize={30} fill="#cddc39" radius={[0, 10, 10, 0]}>
                        <LabelList
                          position="inside"
                          content={this.renderCustomizedLabel}
                        />
                      </Bar>
                      <Bar dataKey="3 Months Ago to Date" barSize={30} fill="#7cb342" radius={[0, 10, 10, 0]}>
                        <LabelList
                          position="inside"
                          content={this.renderCustomizedLabel}
                        />
                      </Bar>
                      <Bar dataKey="Year to Date" barSize={30} fill="#558b2f" radius={[0, 10, 10, 0]}>
                        <LabelList
                          position="inside"
                          content={this.renderCustomizedLabel}
                        />
                      </Bar>
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{
                  marginTop: '30px',
                  width: '100%',
                  textAlign: 'center',
                  height: '350px'
                }}>
                  <ResponsiveContainer>
                    <LineChart
                      key={keyReloadChart}
                      animationBegin={500}
                      animationDuration={3000}
                      animationEasing="linear"
                      data={monthlyMilestonesChartData}
                      margin={{
                        top: 20, right: 0, left: 0, bottom: 0,
                      }}
                    >
                      <CartesianGrid
                        vertical={false}
                        strokeDasharray="4 4"
                        stroke="#aab8c2"
                      />
                      <XAxis fontFamily={'Poppins Regular'} dataKey="mes" style={{
                        fontSize: '10px',
                      }}/>
                      <YAxis
                        fontFamily={'Poppins Regular'}
                        type="number"
                        tickFormatter={this.renderDataFormater}
                        dataKey={(v)=>parseInt(v[milestoneSelected])}
                        domain={[0, 'dataMax+100']}
                        style={{
                        fontSize: '10px',
                        }}
                      />
                      <Tooltip/>
                      <Legend formatter={this.renderColorfulLegendText}/>
                      <Line type="monotone" dataKey={milestoneSelected} stroke="#8884d8" activeDot={{r: 8}}
                            label={this.renderCustomizedLineLabel}/>
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
        }
        <DialogLoans
          dev={dev}
          backEnd={backEnd}
          handleLoading={handleLoading}
          open={openDialogLoans}
          handleClose={this.handleCloseDialogLoans}
          loansData={loansData}
        />
      </Grid>
    )
  }
}

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  tHeader: {
    borderBottom: '1px solid #ccc'
  },
  tHeaderSub: {
    fontSize:'13px'
  },
  tContent: {
    fontSize:'13px',
    textAlign:'center',
    minHeight:'30px',
    padding:'5px'
  },
});

TabMilestones.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TabMilestones);
