import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { store } from "react-notifications-component";

//ICONS
import AccountCircle from "@material-ui/icons/AccountCircle";
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

//COMPONENTES
import DialogAdd from "./DialogAdd";
import axios from "axios";
import DialogConfirmation from "./DialogConfirmation";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogLoan extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      open:false,
      loanId:"",
      openDialogAdd: false,
      dialogAddOption: "",
      loanNumber: "",
      loanNumberE: false,
      borrower: "",
      borrowerE: false,
      borrowerLastName:"",
      borrowerLastNameE:false,
      borrowerFirstName:"",
      borrowerFirstNameE:false,
      startDate: null,
      startDateFixed: null,
      closingDate: null,
      closingDateFixed: null,
      amount: "",
      amountE: false,
      investorId: "",
      investorE: false,
      loId: "",
      loE: false,
      realtorId: "",
      realtorE: false,
      processorId:"",
      processorE:false,
      assignedLOAId:"",
      assignedLOAE:false,
      loanProgramId:"",
      loanProgramE:false,
      loanTypeId:"",
      loanTypeE:false,
      disableForm:true,
      traces: [],
      openDialogConfirmation:false,
      loanStatus: "",
      loanStatusE: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const that = this;
    const { loanSelected, dialogLoanOption } = this.props;
    const { open } = this.state;
    if(nextProps.open && dialogLoanOption === "info" && open === false) {
      this.setState({ open:true}, () => {

        var dateS = loanSelected.fechaInicioLoan;
        var dateDataS = dateS.split("-");
        var y = dateDataS[0];
        var m = dateDataS[1];
        var d = dateDataS[2];
        dateS = m+"/"+d+"/"+y;

        var dateC = loanSelected.closingDate;
        if(dateC !== null && dateC !== "") {
          var dateDataC = dateC.split("-");
          var y = dateDataC[0];
          var m = dateDataC[1];
          var d = dateDataC[2];
          dateC = m + "/" + d + "/" + y;
        }

        this.setState({loanId:loanSelected.loanId, loanNumber:loanSelected.loanNumber, borrower:loanSelected.borrower, borrowerLastName:loanSelected.borrowerLastName, borrowerFirstName:loanSelected.borrowerFirstName, startDate:dateS, closingDate:dateC, amount:loanSelected.amountNumber, investorId:loanSelected.investorId, loId:loanSelected.loId, realtorId:loanSelected.realtorId, processorId:loanSelected.processorId, loanProgramId:loanSelected.loanProgramId, loanTypeId:loanSelected.loanTypeId, assignedLOAId:loanSelected.assignedLOAId, loanStatus: loanSelected.loanStatus }, () => {
          that.loadLoanTraces();
        });
      });
    }
  }

  loadLoanTraces = () => {
    const that = this;
    const { dev, backEnd, loadLoans, dialogLoanOption, handleLoading } = this.props;
    const { loanId } = this.state;
    const dataUser = JSON.parse(localStorage.getItem("_agogosDataUser"));
    var userId = dataUser.userId;

    setTimeout(function (){
      handleLoading(true);
      axios.post(backEnd + "api/home/loans/tracesInfo.php",
        {
          dev:dev,
          userId: userId,
          loanId:loanId
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
        .then(function (response) {
          handleLoading(false);
          var data = response.data;
          var traces = data[0];
          that.setState({ traces: traces});
        })
        .catch(function (error) {
          handleLoading(false);
          var e = JSON.parse(JSON.stringify(error));

          var eText = e.message;
          if (eText === "Network Error") {
            eText = "Error de conexión.";
          }
          if (eText === undefined) {
            eText = "Error unknown";
          }
          store.addNotification({
            title: "Error!",
            message: eText,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        });
    },700);
  }

  fnCreateLoan = () => {
    const that = this;
    const { dev, backEnd, loadLoans, dialogLoanOption, handleLoading } = this.props;
    const {
      loanId,
      loanNumber,
      borrowerLastName,
      borrowerFirstName,
      startDate,
      startDateFixed,
      closingDate,
      closingDateFixed,
      amount,
      investorId,
      loId,
      realtorId,
      processorId,
      loanProgramId,
      loanTypeId,
      assignedLOAId,
      loanStatus,
    } = this.state;
    const dataUser = JSON.parse(localStorage.getItem("_agogosDataUser"));
    var userId = dataUser.userId;

    if (loanStatus === "") {
      this.setState({ loanStatusE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Select the Loan Status.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (loanNumber === "") {
      this.setState({ loanNumberE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write the Loan Number.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (closingDate === null) {
      store.addNotification({
        title: "Warning!",
        message: "Select the Loan Closing Date.",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    } else if (loanProgramId === "") {
      this.setState({ loanProgramE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Select the Loan Program.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (loanTypeId === "") {
      this.setState({ loanTypeE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Select the Loan Type.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (borrowerLastName === "") {
      this.setState({ borrowerLastNameE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write the Borrower Last Name.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (borrowerFirstName === "") {
      this.setState({ borrowerFirstNameE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write the Borrower First Name.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (startDate === null) {
      store.addNotification({
        title: "Warning!",
        message: "Select the Loan Start Date.",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    } else if (amount === "") {
      this.setState({ amountE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Write the Loan Amount.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (investorId === "") {
      this.setState({ investorE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Select the Lender.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (loId === "") {
      this.setState({ loE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Select the Loan Officer.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (realtorId === "") {
      this.setState({ realtorE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Select the Real State Agent.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (processorId === "") {
      this.setState({ processorE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Select the Assigned Proccesor.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else if (assignedLOAId === "") {
      this.setState({ assignedLOAE: true }, () => {
        store.addNotification({
          title: "Warning!",
          message: "Select the Assigned LOA.",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
    } else {
      handleLoading(true);

      var dateS = "";
      if(startDateFixed !== null) {
        dateS = startDateFixed;
      } else if(startDateFixed === null) {
        var dateDataS = startDate.split("/");
        dateS = dateDataS[2]+"-"+dateDataS[0]+"-"+dateDataS[1];
      }

      var dateC = "";
      if(closingDateFixed !== null) {
        dateC = closingDateFixed;
      } else if(closingDateFixed === null) {
        var dateDataC = closingDate.split("/");
        dateC = dateDataC[2]+"-"+dateDataC[0]+"-"+dateDataC[1];
      }

      axios
        .post(
          backEnd + "api/home/loans/addNewLoan.php",
          {
            dev:dev,
            loanId:loanId,
            dialogLoanOption:dialogLoanOption,
            userId: userId,
            loanNumber: loanNumber,
            borrowerLastName: borrowerLastName,
            borrowerFirstName: borrowerFirstName,
            startDate: dateS,
            closingDate: dateC,
            amount: amount,
            investorId: investorId,
            loId: loId,
            realtorId: realtorId,
            processorId: processorId,
            loanProgramId: loanProgramId,
            loanTypeId: loanTypeId,
            assignedLOAId:assignedLOAId,
            loanStatus: loanStatus,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(function (response) {
          handleLoading(false);
          var data = response.data;

          if (data[0].error === 1) {
            setTimeout(function () {
              store.addNotification({
                title: "Success!",
                message: dialogLoanOption === "new" ? "New Loan created succesfully." : "Loan edited succesfully.",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
              that.setState({ investor: "" });
              loadLoans();
              that.handleClose();
            }, 500);
          } else {
            store.addNotification({
              title: "Error!",
              message:dialogLoanOption === "new" ? "An error ocurred when traying to create a new loan. " + data[0].error : "An error ocurred when traying to edit the loan. " + data[0].error,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        })
        .catch(function (error) {
          handleLoading(false);
          var e = JSON.parse(JSON.stringify(error));

          var eText = e.message;
          if (eText === "Network Error") {
            eText = "Error de conexión.";
          }
          if (eText === undefined) {
            eText = "Error unknown";
          }
          store.addNotification({
            title: "Error!",
            message: eText,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        });
    }
  };

  fnDeleteLoan = () => {
    const that = this;
    const { dev, backEnd, loadLoans, handleLoading } = this.props;
    const { loanId } = this.state;
    const dataUser = JSON.parse(localStorage.getItem("_agogosDataUser"));
    var userId = dataUser.userId;
    handleLoading(true);

    axios
      .post(
        backEnd + "api/home/loans/deleteLoan.php",
        {
          dev:dev,
          loanId:loanId,
          userId: userId
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        handleLoading(false);
        var data = response.data;

        if (data[0].error === 1) {
          setTimeout(function () {
            store.addNotification({
              title: "Success!",
              message: "Loan deleted succesfully.",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
            that.setState({ investor: "" });
            loadLoans();
            that.handleClose();
          }, 500);
        } else {
          store.addNotification({
            title: "Error!",
            message:"An error ocurred when traying to delete the loan. " + data[0].error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  handleChange = name => event => {
    if (name === "amount") {
      var amount = event.target.value;
      amount = amount.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      this.setState({ [name]: amount, [name + "E"]: false });
    } else if (event.target.value === "addInvestor") {
      this.setState({ investorId: "" });
      this.handleOpenDialogAdd("addInvestor");
    } else if (event.target.value === "addLo") {
      this.setState({ loId: "" });
      this.handleOpenDialogAdd("addLo");
    } else if (event.target.value === "addRealtor") {
      this.setState({ realtorId: "" });
      this.handleOpenDialogAdd("addRealtor");
    } else {
      this.setState({ [name]: event.target.value, [name + "E"]: false });
    }
  };

  handleClose = () => {
    const { handleClose } = this.props;
    this.setState(this.initialState, () => {
      handleClose();
    });
  };

  handleChangeDate = (date, name) => {
    var d = date.getDate();
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    if(d < 10) {
      d = "0"+d;
    }
    if(m < 10) {
      m = "0"+m;
    }
    var dateFixed = y+"-"+m+"-"+d;
    this.setState({ [name]: date, [name+"Fixed"]:dateFixed });
  };

  handleOpenDialogAdd = (option) => {
    this.setState({ dialogAddOption:option },  () => {
      this.setState({ openDialogAdd: true });
    });
  };

  handleCloseDialogAdd = () => {
    this.setState({ openDialogAdd: false });
  };

  handleOpenDialogConfirmation = () => {
    this.setState({ openDialogConfirmation: true });
  };

  handleCloseDialogConfirmation = () => {
    this.setState({ openDialogConfirmation: false });
  };

  render() {
    const {
      dev,
      backEnd,
      classes,
      dialogLoanOption,
      open,
      los,
      realtors,
      investors,
      processors,
      loansPrograms = [],
      loansTypes = [],
      assignedLOAs,
      loadLoans,
      handleLoading,
    } = this.props;
    const {
      loanId,
      loanNumber,
      loanNumberE,
      borrowerLastName,
      borrowerLastNameE,
      borrowerFirstName,
      borrowerFirstNameE,
      startDate,
      closingDate,
      amount,
      amountE,
      investorE,
      investorId,
      loE,
      loId,
      realtorId,
      realtorE,
      processorE,
      processorId,
      assignedLOAId,
      assignedLOAE,
      openDialogAdd,
      dialogAddOption,
      loanProgramId,
      loanProgramE,
      loanTypeId,
      loanTypeE,
      traces,
      openDialogConfirmation,
      loanStatus,
      loanStatusE,
    } = this.state;

    return (
      <>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          style={{ paddingTop: "30px" }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.handleClose()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {dialogLoanOption === "new" ? "New Loan" : "Loan Info"}
              </Typography>
              {dialogLoanOption === "new" && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={() => this.fnCreateLoan()}
                >
                  Save
                </Button>
              )}
              {dialogLoanOption === "info" && (
                <>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={() => this.fnCreateLoan()}
                  >
                    Save changes
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<DeleteForeverRoundedIcon />}
                    onClick={() => this.handleOpenDialogConfirmation()}
                  >
                    Delete Loan
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container style={{ paddingTop: "40px" }}>
              <Grid item xs={6} lg={4} className={classes.gridPading}>
                <TextField
                  select
                  error={loanStatusE}
                  id="loanStatusId"
                  label="Loan Staus"
                  variant="outlined"
                  onChange={this.handleChange("loanStatus")}
                  value={loanStatus}
                  style={{
                    width: "100%",
                  }}
                >
                  <MenuItem value="Loan">
                    Loan
                  </MenuItem>
                  <MenuItem value="Lead">
                    Lead
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} lg={4} className={classes.gridPading}>
                <TextField
                  error={loanNumberE}
                  id="loanNumber"
                  label="Loan Number"
                  variant="outlined"
                  onChange={this.handleChange("loanNumber")}
                  value={loanNumber}
                  style={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={4} className={classes.gridPading}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ width: "100%" }}
                    inputVariant="outlined"
                    autoOk
                    label="Closing Date"
                    clearable
                    disablePast
                    value={closingDate}
                    format="MM/dd/yyyy"
                    onChange={(date) => this.handleChangeDate(date, "closingDate")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} lg={6} className={classes.gridPading}>
                <TextField
                  select
                  error={loanProgramE}
                  id="loanProgramId"
                  label="Loan Program"
                  variant="outlined"
                  onChange={this.handleChange("loanProgramId")}
                  value={loanProgramId}
                  style={{
                    width: "100%",
                  }}
                >
                  {loansPrograms.map(function (arr, index) {
                    return (
                      <MenuItem key={index} value={arr.loanProgramId}>
                        {arr.loanProgram}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} lg={6} className={classes.gridPading}>
                <TextField
                  select
                  error={loanTypeE}
                  id="loanTypeId"
                  label="Loan Type"
                  variant="outlined"
                  onChange={this.handleChange("loanTypeId")}
                  value={loanTypeId}
                  style={{
                    width: "100%",
                  }}
                >
                  {loansTypes.map(function (arr, index) {
                    return (
                      <MenuItem key={index} value={arr.loanTypeId}>
                        {arr.loanType}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} lg={6} className={classes.gridPading}>
                <TextField
                  error={borrowerLastNameE}
                  id="borrowerLastName"
                  label="Borrower Last Name"
                  variant="outlined"
                  onChange={this.handleChange("borrowerLastName")}
                  value={borrowerLastName}
                  style={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={6} className={classes.gridPading}>
                <TextField
                  error={borrowerFirstNameE}
                  id="borrowerFirstName"
                  label="Borrower First Name"
                  variant="outlined"
                  onChange={this.handleChange("borrowerFirstName")}
                  value={borrowerFirstName}
                  style={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={3} className={classes.gridPading}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ width: "100%" }}
                    inputVariant="outlined"
                    autoOk
                    label="Start Date"
                    clearable
                    disableFuture
                    value={startDate}
                    format="MM/dd/yyyy"
                    onChange={(date) => this.handleChangeDate(date, "startDate")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} lg={3} className={classes.gridPading}>
                <TextField
                  error={amountE}
                  id="amount"
                  label="Loan Amount"
                  variant="outlined"
                  onChange={this.handleChange("amount")}
                  value={amount}
                  style={{
                    width: "100%",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={6} className={classes.gridPading}>
                <TextField
                  select
                  error={investorE}
                  id="investor"
                  label="Lender"
                  variant="outlined"
                  onChange={this.handleChange("investorId")}
                  value={investorId}
                  style={{
                    width: "100%",
                  }}
                >
                  {/* SE COMENTA OPCION PARA AGREGAR INVESTOR 25/09/2020
                    <MenuItem
                    key={0}
                    value={"addInvestor"}
                    style={{
                      borderBottom: "1px solid #ccc",
                      marginBottom: "5px",
                    }}
                  >
                    <AddBoxIcon style={{ marginRight: "10px" }} />
                    Add new investor
                  </MenuItem>
                  */}
                  {investors.map(item => [
                    <ListSubheader key={item.investorCategoryId} style={{
                     color:'#90CAF9'
                    }}>{item.investorCategory}</ListSubheader>,
                    item.investorData.map(subItem => (
                      <MenuItem key={subItem.investorId} value={subItem.investorId}>
                        {subItem.investor}
                      </MenuItem>
                    ))
                  ])}
                </TextField>
              </Grid>
            </Grid>
            <Grid container style={dialogLoanOption !== "new" ? {
              borderBottom:'1px dashed #ccc',
              paddingBottom:'30px'
            } : {}}>
              <Grid item xs={6} lg={6} className={classes.gridPading}>
                <TextField
                  select
                  error={loE}
                  id="lo"
                  label="Loan Officer"
                  variant="outlined"
                  onChange={this.handleChange("loId")}
                  value={loId}
                  style={{
                    width: "100%",
                  }}
                >
                  <MenuItem
                    key={0}
                    value={"addLo"}
                    style={{
                      borderBottom: "1px solid #ccc",
                      marginBottom: "5px",
                    }}
                  >
                    <AddBoxIcon style={{ marginRight: "10px" }} />
                    Add New LO
                  </MenuItem>
                  {los.map(function (arr, index) {
                    return (
                      <MenuItem key={index + 1} value={arr.loId}>
                        {arr.lo}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} lg={6} className={classes.gridPading}>
                <TextField
                  select
                  error={realtorE}
                  id="lo"
                  label="Realtor Partner"
                  variant="outlined"
                  onChange={this.handleChange("realtorId")}
                  value={realtorId}
                  style={{
                    width: "100%",
                  }}
                >
                  <MenuItem
                    key={0}
                    value={"addRealtor"}
                    style={{
                      borderBottom: "1px solid #ccc",
                      marginBottom: "5px",
                    }}
                  >
                    <AddBoxIcon style={{ marginRight: "10px" }} />
                    Add New Agent
                  </MenuItem>
                  {realtors.map(function (arr, index) {
                    return (
                      <MenuItem key={index + 1} value={arr.realtorId}>
                        {arr.realtor}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} lg={6} className={classes.gridPading}>
                <TextField
                  select
                  error={processorE}
                  id="processor"
                  label="Assigned Processor"
                  variant="outlined"
                  onChange={this.handleChange("processorId")}
                  value={processorId}
                  style={{
                    width: "100%",
                  }}
                >
                  {processors.map(function (arr, index) {
                    return (
                      <MenuItem key={index} value={arr.processorId}>
                        {arr.processor}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} lg={6} className={classes.gridPading}>
                <TextField
                  select
                  error={assignedLOAE}
                  id="assignedLOAId"
                  label="Assigned LOA"
                  variant="outlined"
                  onChange={this.handleChange("assignedLOAId")}
                  value={assignedLOAId}
                  style={{
                    width: "100%",
                  }}
                >
                  {assignedLOAs.map(function (arr, index) {
                    return (
                      <MenuItem key={index} value={arr.assignedLOAId}>
                        {arr.assignedLOA}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            {dialogLoanOption !== "new" &&
              <Grid container>
                <Grid item xs={12}>
                  <div style={{
                    width: "100%",
                    textAlign: "right",
                    marginTop:'10px'
                  }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddBoxIcon />}
                      onClick={()=>this.handleOpenDialogAdd("addTrace")}
                    >
                      Add Updates
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <List className={classes.root}>
                    {Object.keys(traces).length > 0 &&
                      traces.map(function (arr, index) {

                        var dO = arr.dateSeguimiento.split(" ");
                        var dd = dO[0].split("-");

                        var y = dd[0];
                        var m = dd[1];
                        var d = dd[2];
                        var dateFixed = m+"/"+d+"/"+y+" "+dO[1];

                        return (
                          <>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar>
                                {arr.userName.charAt(0)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={dateFixed}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                  >
                                    {arr.userName}
                                  </Typography>
                                  {" — "+arr.seguimiento}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                        </>
                      )})
                    }
                  </List>
                </Grid>
              </Grid>
            }
          </DialogContent>
        </Dialog>
        <DialogConfirmation
          open={openDialogConfirmation}
          handleClose={this.handleCloseDialogConfirmation}
          fnOk={this.fnDeleteLoan}
          title="Are you sure?"
          text={
            <font>
              Your are going to delete this loan.
              <br></br> This action cannot be undone.{" "}
            </font>
          }
        />
        <DialogAdd
          dev={dev}
          loanId={loanId}
          loadLoans={loadLoans}
          loadLoanTraces={this.loadLoanTraces}
          backEnd={backEnd}
          open={openDialogAdd}
          option={dialogAddOption}
          handleClose={this.handleCloseDialogAdd}
          handleLoading={handleLoading}
        />
      </>
    );
  }
}

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  gridPading: {
    padding:'15px 10px'
  },
  inline: {
    display: 'inline',
  },
});

DialogLoan.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogLoan);
