import React from "react";
import axios from "axios";
import { curi } from "@curi/router";
import Browser from "@hickory/browser";
import { curiProvider } from "@curi/react-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";

import "./App.css";
import routes from "./routes";

//COMPONENTES
import TopAppBar from "./commons/TopAppBar";
import DialogNewVersion from "./commons/DialogNewVersion";

const history = Browser();
const router = curi(history, routes);
const Router = curiProvider(router);

//MAJOR.MINOR.PATCH.BUILD
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dev: false,
      backEnd: "https://agogos.cloudgia.com/",
      login: false,
      version: "1.2.14",
      build: 22,
      newVersion: "",
      intervalCheckVersion: "",
      openDialogNewVersion: false,
      showVersionImprovements: false,
      versionImprovements: [],
      versionLog: [],
      pageHeight: 0,
      pageWidth: 0
    };
    this.handleDimensions = this.handleDimensions.bind(this);
  }

  componentDidMount() {
    const that = this;
    this.handleCheckSession();
    this.handleCheckVersion();
    this.handleDimensions();
    this.setState({
      intervalCheckSistemVersion: setInterval(this.handleCheckVersion, 80000),
    });
    window.addEventListener('resize', this.handleDimensions);
  }

  //MANEJADOR PARA REVISAR EL INICIO DE SESION
  handleCheckSession = () => {
    const login = JSON.parse(localStorage.getItem("_agogosLogin"));
    const page = localStorage.getItem("_agogosPage");
    if(login) {
      this.setState({ login: true }, () => {
        router.navigate({ name: page });
      });
    } else {
      router.navigate({ name: "login" });
    }
  };

  //MANEJADOR PARA REVISAR LA VERSION DEL SISTEMA CONTRA LA BASE DE DATOS
  handleCheckVersion = () => {
    const that = this;
    const { dev, backEnd, build, version } = this.state;
    var updated = JSON.parse(localStorage.getItem("_agogosUpdated"));
    var v = version+"."+build;

    if(updated) {
      store.addNotification({
        title: "Success!",
        message: "Agogos updated succesfully.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
      localStorage.setItem('_agogosUpdated', JSON.stringify(false));
    }

    axios.post(backEnd + "api/commons/checkVersion.php",{
      version: v,
      dev:dev
      },{
        headers: { "Content-Type": "application/json" },
      }
    ).then(function (response) {
      var data = response.data;
      var newVersion = data[0];
      var versionLog = data[2];
      var versionImprovements = data[1];
      that.setState({ versionLog: versionLog });
      if (v !== newVersion) {
        that.setState({ newVersion:newVersion, versionImprovements:versionImprovements }, () => {
          that.handleOpenDialogNewVersion();
        });
      }
    }).catch(function (error)
    {
      that.setState({ loadingLogin: false });
      var e = JSON.parse(JSON.stringify(error));

      var eText = e.message;
      if (eText === "Network Error") {
        eText = "Error de conexión.";
      }
      if (eText === undefined) {
        eText = "Error unknown";
      }
      store.addNotification({
        title: "Error!",
        message: eText,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    });
  }

  //MANEJADOR PARA INICIAR SESION
  handleLogin = (dataUser) => {
    localStorage.setItem('_agogosDataUser', JSON.stringify(dataUser));
    localStorage.setItem('_agogosLogin', JSON.stringify(true));
    localStorage.setItem('_agogosPage', 'home');
    this.setState({ login: true }, () => {
      router.navigate({ name: "home" });
    });
  }

  //MANEJADOR PARA CERRAR SESION
  handleLogOut = () => {
    const { intervalCheckVersion } = this.state;
    localStorage.clear();
    clearInterval(intervalCheckVersion);
    this.setState({ login: false }, () => {
      router.navigate({ name: "login" });
    });
  }

  //MANEJADOR PARA ACTUALIZAR DIMENSIONES DE LA PAGINA
  handleDimensions = () => {
    this.setState({ pageWidth: window.innerWidth, pageHeight: window.innerHeight });
  }

  //MANEJADOR PARA ABRIR DIALOGO DE NUEVA VERSION O CONSULTA DE VERSIONES
  handleOpenDialogNewVersion = (option) => {
    this.setState({ openDialogNewVersion:true });
    if (option === 1) {
      this.setState({ showVersionImprovements:true });
    }
  }

  //MANEJADOR PARA CERRAR DIALOGO DE NUEVA VERSION O CONSULTA DE VERSIONES
  handleCloseDialogNewVersion = () => {
    this.setState({ openDialogNewVersion:false, showVersionImprovements:false });
  }

  //MANEJADOR PARA HACER UPDATE DE LA VERSION
  handleUpdateVersion = () => {
    localStorage.setItem('_agogosUpdated', JSON.stringify(true));
    window.location.reload();
  }

  render() {
    const { dev, build, version, backEnd, pageHeight, pageWidth, login, openDialogNewVersion, newVersion, versionImprovements, versionLog, showVersionImprovements } = this.state;
    return (
      <>
        <ReactNotification />
        <MuiThemeProvider theme={theme}>
          {login &&
          <TopAppBar handleLogOut={this.handleLogOut}/>
          }
          <Router>
            {({ response, router }) => {
              const { body: Body } = response;
              return (
                <Body
                  dev={dev}
                  backEnd={backEnd}
                  build={build}
                  version={version}
                  pageWidth={pageWidth}
                  pageHeight={pageHeight}
                  handleLogin={this.handleLogin}
                  handleOpenDialogNewVersion={this.handleOpenDialogNewVersion}
                />
              );
            }}
          </Router>
          <DialogNewVersion
            open={openDialogNewVersion}
            showVersionImprovements={showVersionImprovements}
            versionLog={versionLog}
            versionImprovements={versionImprovements}
            newVersion={newVersion}
            version={version}
            handleClose={this.handleCloseDialogNewVersion}
            handleUpdateVersion={this.handleUpdateVersion}
          />
        </MuiThemeProvider>
      </>
    );
  }
}

//TEMA AGOGOS
const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins Regular",
  },
  palette: {
    type: "dark",
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: '1px solid #f50057'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "rgba(255, 255, 255, 0.7)",
        },
        "&$focused $notchedOutline": {
          borderColor: "#FFF",
        },
        legend: {
          width: "calc(8px + var(--label-width) * 0.75) !important",
        },
      },
      notchedOutline: {
        borderColor: "rgba(255, 255, 255, 0.7)",
      },
      input: {
        color: "rgba(255, 255, 255, 0.7)",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "rgba(255, 255, 255, 0.7)",
      },
    },
    MuiInputLabel: {
      shrink: {
        background: "transparent !important",
        color: "#FFF !important",
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(255, 255, 255, 0.7)",
        fontWeight: "200",
      },
    },
    MuiBackdrop: {
      root: {
        zIndex: 0,
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#323232'
      }
    },
    MuiDialog: {
      paperFullScreen: {
        maxWidth:'1000px',
        width:'97%'
      }
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#323232'
      }
    },
    MuiTableCell: {
      body: {
        padding: '9px 15px !important',
        fontSize: '13px !important'
      }
    }
  }
});

export default App;
