import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import PropTypes from 'prop-types';
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { store } from "react-notifications-component";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';

//COMPONENTES
import DialogLoan from "../components/DialogLoan";
import DialogConfirmation from "../components/DialogConfirmation";

class TabLoans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      los: [],
      realtors: [],
      investors: [],
      dataUser: [],
      loanSelected: [],
      milestons: [],
      processors: [],
      loansPrograms: [],
      loansTypes: [],
      assignedLOAs: [],
      openDialogLoan: false,
      dialogLoanOption: "",
      openDialogConfirmation: false,
      milestonToChange: "",
      milestonIdToChange: "",
      loanIdMilestonToChange: "",
      intervalId:"",
      typeAlert:""
    };
  }

  componentDidMount() {
    const dataUser = JSON.parse(localStorage.getItem("_agogosDataUser"));
    this.setState({ dataUser: dataUser }, () => {
      this.loadLoans();
      //var intervalId = setInterval(this.loadLoans, 20000);
      //this.setState({intervalId: intervalId});
    });
  }

  componentWillUnmount() {
    console.log("adios...");
    //clearInterval(this.state.intervalId);
  }

  loadLoans = () => {
    const that = this;
    const { dev, backEnd, handleLoading } = this.props;
    const { dataUser, intervalId } = this.state;
    var userId = dataUser.userId;

    if(intervalId === "") {
      handleLoading(true);
    }

    axios
      .post(
        backEnd + "api/home/loans/loansInfo.php",
        {
          dev:dev,
          userId: userId,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        handleLoading(false);
        var data = response.data;
        var loans = data[0];
        var los = data[1];
        var realtors = data[2];
        var investors = data[3];
        var milestons = data[4];
        var processors = data[6];
        var loansPrograms = data[7];
        var loansTypes = data[8];
        var assignedLOAs = data[9];

        that.setState({
          loans: loans,
          los: los,
          realtors: realtors,
          investors: investors,
          milestons: milestons,
          processors: processors,
          loansPrograms: loansPrograms,
          loansTypes: loansTypes,
          assignedLOAs: assignedLOAs
        });
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  };

  fnChangeMileston = () => {
    const that = this;
    const { dev, backEnd, handleLoading } = this.props;
    const { dataUser, milestonIdToChange, loanIdMilestonToChange } = this.state;
    var userId = dataUser.userId;

    that.handleCloseDialogConfirmation();
    handleLoading(true);
    console.log(milestonIdToChange);
    axios
      .post(
        backEnd + "api/home/loans/updateMileston.php",
        {
          dev:dev,
          userId: userId,
          milestonId: milestonIdToChange,
          loanId: loanIdMilestonToChange,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        handleLoading(false);
        var data = response.data;

        if(milestonIdToChange === "17") {
          console.log("send to whiteboard information...");
          var dataWhiteBoard = data[1];
          console.log(dataWhiteBoard);
          that.fnSendDataToWhiteBoard(dataWhiteBoard);
        }

        if (data[0].error === 1) {
          setTimeout(function () {
            store.addNotification({
              title: "Success!",
              message: "Milestone updated succesfully.",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
            that.loadLoans();
          }, 500);
        } else {
          store.addNotification({
            title: "Error!",
            message:
              "There was an error when traying to update the milestone. " +
              data[0].error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  };

  fnSendDataToWhiteBoard = (dataWhiteBoard) => {
    if(Object.keys(dataWhiteBoard).length > 0) {
      axios.post(
          "https://leads.whiteboard.software/2237FRR-1BVOGRK-8XDVEVL-SBHRJDB-T4FFMOE-SY0BOCG/",
          dataWhiteBoard,
          {
            headers: {"Content-Type": "application/json"},
          }
        )
        .then(function (response) {
          var data = response.data;
          console.log('Response Whiteboard');
          console.log(data);
        })
        .catch(function (error) {
          var e = JSON.parse(JSON.stringify(error));

          var eText = e.message;
          if (eText === "Network Error") {
            eText = "Error de conexión.";
          }
          if (eText === undefined) {
            eText = "Error unknown";
          }
          store.addNotification({
            title: "Error!",
            message: eText,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        });
    }
  }

  fnChangeMilestonReceived = () => {
    const that = this;
    const { dev, backEnd, handleLoading } = this.props;
    const { dataUser, milestonToChange, milestonIdToChange, loanIdMilestonToChange } = this.state;
    var userId = dataUser.userId;

    that.handleCloseDialogConfirmation();
    handleLoading(true);

    axios
      .post(
        backEnd + "api/home/loans/updateMilestonReceived.php",
        {
          dev:dev,
          userId: userId,
          milestonId: milestonIdToChange,
          loanId: loanIdMilestonToChange,
          received: milestonToChange
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        handleLoading(false);
        var data = response.data;

        if (data[0].error === 1) {
          setTimeout(function () {
            store.addNotification({
              title: "Success!",
              message: "Received marked succesfully.",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
            that.loadLoans();
          }, 500);
        } else {
          store.addNotification({
            title: "Error!",
            message:
              "There was an error when traying to mark as received the milestone. " +
              data[0].error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  handleOpenDialogLoan = (rowData, option) => {
    this.setState({ loanSelected: rowData, dialogLoanOption: option }, () => {
      this.setState({ openDialogLoan: true });
    });
  };

  handleCloseDialogLoan = () => {
    this.setState({ loanSelected: [], openDialogLoan: false });
  };

  handleOpenDialogConfirmation = () => {
    this.setState({ openDialogConfirmation: true });
  };

  handleCloseDialogConfirmation = () => {
    this.setState({ openDialogConfirmation: false });
  };

  handleChangeMileston = (value, loanId) => {
    const { milestons } = this.state;
    //this.setState({ [name]: event.target.value, [name + "E"]: false });
    var indexMilestons = milestons.findIndex((x) => x.milestonId === value);
    var mileston = milestons[indexMilestons].mileston;
    this.setState(
      {
        milestonToChange: mileston,
        milestonIdToChange: value,
        loanIdMilestonToChange: loanId,
        typeAlert:"changeMilestone"
      },
      () => {
        this.handleOpenDialogConfirmation();
      }
    );
  };

  handleChangeMilestonReceived = (value, loanId, milestoneId) => {
    this.setState({
      milestonToChange:value,
      milestonIdToChange:milestoneId,
      loanIdMilestonToChange:loanId,
      typeAlert:"changeMilestoneReceived"
    },
      () => {
        this.handleOpenDialogConfirmation();
      }
    );
  }

  handleTestWebhook = () => {
    const that = this;
    const { backEnd } = this.props;
    //TEST WEBHOOK
    axios.post(backEnd + "api/webhook/agogosCom.php",{
      borrowerFirstName: "James",
      borrowerLastName: "Testington",
      borrowerEmail: "James.Testington@email.com",
      borrowerCellphone: "(456) 789-4555",
      mortgageLosNumber: "aflgthy3456678",
      applicationDate: "2020-11-04",
      loanPurpose: "purchase",
      occupancyType: "Primary Residence",
      requestedLoanAmount: "$456,000",
      loanAmount: "$450,000",
      loanProgram: "conventional",
      interestRate: "3.82",
      loanTerms: "fixed",
      salesPrice: "$600,000",
      propertyAddressOne: "4115 Timber Trail",
      propertyCity: "Medina",
      propertyState: "OH",
      propertyZip: "44256"
      },{
        headers: { "Content-Type": "application/json" },
      }
    ).then(function (response) {
      var data = response.data;
      console.log(data);
    });
  }

  render() {
    const { dev, backEnd, classes, handleLoading } = this.props;
    const {
      loans,
      openDialogLoan,
      dialogLoanOption,
      los,
      realtors,
      investors,
      loanSelected,
      milestons,
      openDialogConfirmation,
      milestonToChange,
      processors,
      typeAlert,
      loansPrograms,
      loansTypes,
      assignedLOAs
    } = this.state;

    return (
      <Grid container>
        <Grid item xs={12}>
          <div style={{ width: "100%", textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddBoxIcon />}
              onClick={() => this.handleOpenDialogLoan([], "new")}
            >
              New Loan
            </Button>
            {dev &&
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AddBoxIcon/>}
                onClick={() => this.handleTestWebhook()}
              >
                Test Weebhook
              </Button>
            }
          </div>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Active Pipeline by Completed Milestone"
            columns={[
              { title: "ID", field: "loanId", editable: "never", hidden: true },
              {
                title: "Loan Number",
                field: "loanNumber",
                cellStyle: (e, rowData) => {
                  if (rowData.loanStatus === "Lead") {
                    return { backgroundColor: "#fff59d", color: '#424242', textAlign: 'left' };
                  } else if(rowData.loanStatus === "Loan") {
                    return { backgroundColor: '#c5e1a5', color: '#424242', textAlign: 'left' }
                  } else {
                    return { textAlign: 'left' };
                  }
                },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
                render: (rowData) => {
                  return (
                    <font
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() => this.handleOpenDialogLoan(rowData, "info")}
                    >
                      {rowData.loanNumber}
                    </font>
                  );
                },
              },
              {
                title: "Status",
                field: "loanStatus",
                width: 20,
                cellStyle: (e, rowData) => {
                  if (rowData.loanStatus === "Lead") {
                    return { backgroundColor: "#fff59d", color: '#424242', textAlign: 'left' };
                  } else if(rowData.loanStatus === "Loan") {
                    return { backgroundColor: '#c5e1a5', color: '#424242', textAlign: 'left' }
                  } else {
                    return { textAlign: 'left' };
                  }
                },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
              },
              {
                title: "Borrower",
                field: "borrower",
                cellStyle: { textAlign: "left" },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
              },
              {
                title: "Start Date",
                field: "fechaInicioLoan",
                cellStyle: { textAlign: "left" },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
                render: (rowData) => {
                  var dateS = rowData.fechaInicioLoan;
                  var dateDataS = dateS.split("-");
                  var y = dateDataS[0];
                  var m = dateDataS[1];
                  var d = dateDataS[2];
                  dateS = m+"/"+d+"/"+y;
                  return (
                    dateS
                  );
                },
              },
              {
                title: "Loan Amount",
                field: "amount",
                cellStyle: { textAlign: "left" },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
              },
              {
                title: "Loan Officer",
                field: "lo",
                cellStyle: { textAlign: "left" },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
              },
              {
                title: "Realtor Partner",
                field: "realtor",
                cellStyle: { textAlign: "left" },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
              },
              {
                title: "Lender",
                field: "investor",
                cellStyle: { textAlign: "left" },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
              },
              {
                title: "Milestone",
                field: "mileston",
                cellStyle: { textAlign: "left" },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
                render: (rowData) => {
                  if (rowData.loanStatus === 'Loan') {
                    return (
                      <div style={{minWidth: '250px'}}>
                        <MuiThemeProvider theme={themeMilestoneSelect}>
                          <TextField
                            select
                            id="mileston"
                            label="Milestone"
                            variant="outlined"
                            value={rowData.milestonId}
                            onChange={(event) =>
                              this.handleChangeMileston(
                                event.target.value,
                                rowData.loanId
                              )
                            }
                            style={{
                              width: rowData.milestonReceived === "1" ? '60%' : '100%',
                            }}
                          >
                            {
                              milestons.map(function (arr, index) {
                                // var actualIndex =  parseInt(rowData.milestonId);
                                // if ((index + 1) >= actualIndex || (index + 1) === 1) {
                                return (
                                  <MenuItem key={index} value={arr.milestonId}>
                                    {arr.mileston}
                                  </MenuItem>
                                );
                                // }
                              })
                            }
                          </TextField>
                        </MuiThemeProvider>
                        {rowData.milestonReceived === "1" &&
                          <MuiThemeProvider theme={themeMilestoneSelect}>
                            <TextField
                              select
                              id="milestonReceived"
                              label="Received"
                              variant="outlined"
                              value={rowData.milestonReceivedStatus}
                              onChange={(event) =>
                                this.handleChangeMilestonReceived(
                                  event.target.value,
                                  rowData.loanId,
                                  rowData.milestonId
                                )
                              }
                              style={{
                                width: '40%',
                              }}
                            >
                              {rowData.milestonReceivedStatus == "0" &&
                                <MenuItem value={0}>
                                  No
                                </MenuItem>
                              }
                              <MenuItem value={1}>
                                Yes
                              </MenuItem>
                            </TextField>
                          </MuiThemeProvider>
                        }
                      </div>
                    );
                  } else {
                    return (
                      <div style={{minWidth: '250px'}}>
                        <MuiThemeProvider theme={themeMilestoneSelect}>
                          <TextField
                            select
                            id="mileston"
                            label="Milestone"
                            variant="outlined"
                            value={rowData.milestonId}
                            onChange={(event) =>
                              this.handleChangeMileston(
                                event.target.value,
                                rowData.loanId
                              )
                            }
                            style={{
                              width: '100%',
                            }}
                          >
                            <MenuItem value={1}>
                              {milestons[0].mileston}
                            </MenuItem>
                            <MenuItem value={20}>
                              {milestons[19].mileston}
                            </MenuItem>
                          </TextField>
                        </MuiThemeProvider>
                      </div>
                    );
                  }
                },
              },
              {
                title: "Remaining days",
                field: "daysLeft",
                cellStyle: (e, rowData) => {
                  if (rowData.status === "red") {
                    return { backgroundColor: "#F48FB1", textAlign: 'center' };
                  } else if(rowData.status === "yellow") {
                    return { backgroundColor: '#ffb74d', textAlign: 'center' }
                  } else if(rowData.status == 'green') {
                    return { backgroundColor: '#8bc34a', textAlign: 'center' }
                  } else {
                    return { textAlign: 'center' };
                  }
                },
                headerStyle: { color:'#90CAF9', borderBottom:'1px solid #FFF' },
                render: (rowData) => {
                  return (
                    <div>
                      {(rowData.status !== "gray" && rowData.status !== "green") ? rowData.daysLeft : '-'}
                    </div>
                  )
                }
              },
            ]}
            data={loans}
            options={{
              exportButton: true,
              sorting: true,
              pageSize:10,
              pageSizeOptions:[10,25,50,100],
              rowStyle: {
                textAlign: "center",
              },
            }}
          />
        </Grid>
        <DialogLoan
          dev={dev}
          backEnd={backEnd}
          dialogLoanOption={dialogLoanOption}
          open={openDialogLoan}
          los={los}
          realtors={realtors}
          investors={investors}
          loanSelected={loanSelected}
          processors={processors}
          handleLoading={handleLoading}
          loansPrograms={loansPrograms}
          loansTypes={loansTypes}
          assignedLOAs={assignedLOAs}
          loadLoans={this.loadLoans}
          handleClose={this.handleCloseDialogLoan}
        />
        <DialogConfirmation
          open={openDialogConfirmation}
          handleClose={this.handleCloseDialogConfirmation}
          fnOk={
            typeAlert === "changeMilestone" ?
            this.fnChangeMileston :
            typeAlert === "changeMilestoneReceived" &&
            this.fnChangeMilestonReceived
          }
          title="Are you sure?"
          text={
            typeAlert === "changeMilestone" ?
            <font>
              Your are going to change the loan milestone to: <br></br>{" "}
              <font style={{ fontWeight: "bold" }}>{milestonToChange}</font>{" "}
              <br></br> This action cannot be undone.{" "}
            </font>
              :
            typeAlert === "changeMilestoneReceived" &&
            <font>
              Your are going to mark as Received <br></br>{" "}
              <br></br> This action cannot be undone.{" "}
            </font>
          }
        />
      </Grid>
    );
  }
}

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
});

TabLoans.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

//TEMA AGOGOS
const themeMilestoneSelect = createMuiTheme({
  typography: {
    fontFamily: "Poppins Regular",
  },
  palette: {
    type: "dark",
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: '1px solid #f50057'
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        color: "rgba(255, 255, 255, 0.7)",
      },
    },
    MuiInputLabel: {
      shrink: {
        background: "transparent !important",
        color: "#FFF !important",
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(255, 255, 255, 0.7)",
        fontWeight: "200",
      },
    },
    MuiBackdrop: {
      root: {
        zIndex: 0,
      },
    },
    MuiSelect: {
      selectMenu: {
        fontSize:'13px'
      }
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "rgba(255, 255, 255, 0.7)",
        },
        "&$focused $notchedOutline": {
          borderColor: "#FFF",
        },
        legend: {
          width: "calc(8px + var(--label-width) * 0.75) !important",
        },
      },
      notchedOutline: {
        borderColor: "rgba(255, 255, 255, 0.7)",
      },
      input: {
        color: "rgba(255, 255, 255, 0.7)",
        padding:'10px 14px'
      }
    }
  }
});

export default withStyles(styles, { withTheme: true })(TabLoans);
