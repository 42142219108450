import React from "react";
import PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

//COMPONENTES
import TabLoans from "./TabLoans/TabLoans";
import TabMilestones from "./TabMilestones/TabMilestones";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      value:0
    };
  }

  handleChange = (event, newValue) => {
    this.setState({value:newValue});
  };

  handleLoading = (option) => {
    this.setState({loading:option});
  }

  render() {
    const { dev, backEnd, pageHeight } = this.props;
    const { loading, value } = this.state;

    return (
      <>
        <div
          className="main"
          style={{
            height: pageHeight + "px",
            marginTop: "0px",
            background:"#212121",
            maxHeight: pageHeight + "px",
            overflowY: "auto",
          }}
        >
          <AppBar position="static" elevation={0} style={{ marginTop: "64px" }}>
            <Tabs
              value={value}
              onChange={this.handleChange}
              aria-label="simple tabs example"
              indicatorColor="secondary"
            >
              <Tab label="Loan" {...a11yProps(0)} />
              <Tab label="Current Fundings" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <TabLoans dev={dev} backEnd={backEnd} handleLoading={this.handleLoading}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabMilestones dev={dev} backEnd={backEnd} handleLoading={this.handleLoading}/>
          </TabPanel>
        </div>
        <Backdrop open={loading} style={{zIndex:1000000000}}>
          <CircularProgress style={{ color: "#FFF" }} />
        </Backdrop>
      </>
    );
  }
}

export default HomePage;
